import {retrieveJWT} from "./LocalStorageService";

const axios = require('axios');

const generateHeaders = () => {
    const JWT = retrieveJWT();
    return {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${JWT}`
        }
    }
}

const generateHeadersTCH = (_payload: any) => {
    // const JWT = retrieveJWT();

    return {
        method: 'post',
        url: process.env.REACT_APP_REST_ENDPOINT_TCH,
        data : JSON.stringify(_payload)
    }
}

export async function post(_module: string, _data: any) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.post(url + _module, _data, generateHeaders());
    return response.data;
}

export async function getAll(_module: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.get(url + _module, generateHeaders());
    return response.data;
}

export async function getById(_module: string, _id: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.get(url + _module + '/' + _id, generateHeaders());
    return response.data;
}

export async function patch(_module: string, _id: string, _data: any) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.patch(url + _module + '/' + _id, _data, generateHeaders());
    return response.data;
}

export async function deleteById(_module: string, _id: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT;
    const response = await axios.delete(url + _module + '/' + _id, generateHeaders());
    return response.data;
}

export async function postTCH( _data: any) {
    const url = process.env.REACT_APP_REST_ENDPOINT_TCH;
    const response = await axios(generateHeadersTCH(_data));
    return response.data;
}

export async function getFR(_module: string) {
    const url = process.env.REACT_APP_REST_ENDPOINT_FACIAL_REC;
    const response = await axios.get(url + _module, generateHeaders());
    return response.data;
}
