import React, {useRef} from 'react';
import {Button, Card, CardContent, TextField, Typography, Box, FormControl} from "@mui/material";
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import '../../../../index.scss';
import {useTranslation, Trans} from 'react-i18next';
import {useForm} from "react-hook-form";
import {post} from "../../../../Services/GenericApiService";
import AlertM from "../../../Helpers/AlertM/AlertM";
import {encrypt} from "../../../../Services/CryptoService";
import {getUserData, storeJWT} from "../../../../Services/LocalStorageService";
import {login} from "./Actions";


const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")


function Login() {
    // styles
    const classes = useStyles();

    // router
    const navigate = useNavigate();

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'user/auth/email';

    // state in redux
    const authentication = useSelector(
        (state: RootStateOrAny) => state.authentication,
    );

     // Dispatcher
    const dispatch = useDispatch()

    // form data
    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // ui controls
    const [loading, setLoading] = React.useState(false);

    // authentication methods
    const onLogin = (_data: any) => {
        setLoading(true); // show loader

        // encrypt data
        _data.password = encrypt(_data.password)

        post(moduleMain, _data)
            .then(_res => {
                setLoading(false); // hide loader
                storeJWT(_res.token)
                const userdata = getUserData();
                dispatch(login(true, _res.token, userdata));
                navigate('/dashboard');
            })
            .catch(_err => {
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('login.loginFailed'), t('login.invalidCredentials'));
            })
    }

    return (
        <section className="full-width h-100">
            <div className="row h-100">
                <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="px-md-0 px-4">

                            <div className="col-md-12 mx-md-2 mx-0">
                                <h2>{t('module.login')}</h2>
                                <p>{t('login.tagline')}</p>
                            </div>

                            <form className="row px-2" onSubmit={handleSubmit(onLogin)}>

                                {/* Field Email */}
                                <div className="col-md-12 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                                   {...register("email", {required: true})}
                                                   label={t('form.email')}
                                                   type={"email"}
                                                   error={!!errors.email}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Field Password */}
                                <div className="col-md-12 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="passwordInput"
                                                   {...register("password", {required: true})}
                                                   label={t('form.password')}
                                                   type={"password"}
                                                   error={!!errors.password}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>

                                {/* Submit Button */}
                                <div className="col-md-12 mt-4">
                                    <Button type="submit"
                                            variant="contained"
                                            className={'bg-custom-gradient my-2 py-3 full-width'}>
                                        {t('module.login')}
                                    </Button>
                                </div>

                            </form>

                            <div className="col-md-12">
                                <p className="my-2 mx-md-2 mx-0">
                                    {t('login.dontHaveAccount')}&nbsp;
                                    <span className="fw-bolder text-theme-dark" onClick={() => navigate('/register')}>
                                    {t('module.register')}
                                </span>
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="">
                        <Card sx={{m: 3, minHeight: '95vh', boxShadow: 10}} className={'bg-custom-gradient'}>
                            <CardContent>

                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>


            <AlertM ref={notifications}/>

        </section>
    );
}


export default Login;
