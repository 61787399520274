import React, {useCallback, useEffect, useState} from 'react';
import useFileUpload from 'react-use-file-upload';

function BasicDropzoneM(props: any) {
    
    return (
        <h2>
            Upload Documents
        </h2>
    );
}

export default BasicDropzoneM;
