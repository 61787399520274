import React, {useContext} from 'react';
import {
    Button,
    Card,
    CardContent,
    TextField,
    Typography,
    Box,
    Snackbar,
    Alert,
    AlertTitle,
    LinearProgress
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {useStyles} from './Styles';
import {RootStateOrAny, useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getFR} from '../../../../Services/GenericApiService';
import {useForm} from "react-hook-form";
import Webcam from "react-webcam";
import config from '../../../../config/config';
import {SocketContext} from '../../../../config/socket';
import {patch, post} from "../../../../Services/GenericApiService";

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")


// helper methods
const sleep = (milliseconds: any) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}


function RegisterFace({empId = null}) {
    const classes = useStyles();
    const navigate = useNavigate();

    const moduleTrainClassifier = 'train';

    // refs
    const webcamRef = React.useRef(null);
    const imgRef = React.useRef(null);

    // data vars
    let socketIOStreamingId = 0;
    const [searchParams, setSearchParams] = useSearchParams();
    const [employeeId, setEmployeeId] = React.useState(searchParams.get('empId') != null ? searchParams.get('empId') : (empId != null ? empId : ''));
    const [isInputEnabled, setIsInputEnabled] = React.useState(searchParams.get('enableInput') != null ? searchParams.get('enableInput') : (empId != null ? empId : ''));
    // const [socketIOStreamingId, setSocketIOStreamingId] = React.useState(0);
    const socket = useContext(SocketContext);

    // ui controls
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showWebcam, setShowWebcam] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [isFaceRegistered, setIsFaceRegistered] = React.useState(false);

    // video streaming methods
    function stopVideoStreamingToServer() {
        console.log('stopped training');
        console.log(socketIOStreamingId);
        clearInterval(socketIOStreamingId);
        // @ts-ignore
        socket.off();
        // setEmployeeId('');
        setShowWebcam(false);
    }

    async function startTrainingVideoStreamingToServer(_employeeId: any) {
        // training mode
        console.log('started training');
        // start training logic

        let intervalId = setInterval(() => {
            // @ts-ignore
            socket.emit("training_frame", {
                // @ts-ignore
                frame: webcamRef.current.getScreenshot(),
                employeeId: _employeeId
            });
            // console.log('interval');
        }, (config.FPS * 1000));

        console.log(intervalId);

        // @ts-ignore
        // setSocketIOStreamingId(intervalId);
        socketIOStreamingId = intervalId;


        setProgress(0);
        console.log('here');

        for(let i=0; i<100; i++){
            await sleep(1000);
            try{
                // @ts-ignore
                if(webcamRef.current.canvas == null || webcamRef.current == null){
                    // permissions not accepted yet
                } else {
                    break;
                }
            } catch (e) {
                // pass
            }
        }

        for (let i = 0; i <= 10; i++) {
            setProgress((prevProgress) => {
                return (prevProgress >= 100 ? 100 : prevProgress + 10)
            });
            await sleep(1000);
        }


        registerDataset();
        setProgress(0);
        stopVideoStreamingToServer();
        // setOpenSuccessAlert(true);  // show success alert
        setIsFaceRegistered(true);

    }

    // on change/ on click methods
    const handleEmployeeIdChange = (e: any) => {
        setEmployeeId(e.target.value);
    }

    const onClickRegisterButton = () => {
        if (!showWebcam) {
            startTrainingVideoStreamingToServer(employeeId);
        } else {
            stopVideoStreamingToServer()
        }
        setShowWebcam(!showWebcam);
    }


    // ui toggle methods
    const handleCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };


    // APIs
    function registerDataset() {
        getFR(moduleTrainClassifier)
            .then((_res: any) => {
            }, (_err: any) => {
                console.log(_err);
            });
    }


    return (
        <section className="full-width">
            <div className="row">

                {/* Content Div*/}
                <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="px-md-5 px-4 full-width">

                            {!isFaceRegistered &&
                                <div>
                                    {!showWebcam &&
                                        <div className="my-5">
                                            {/*<h1 className="mb-3 fw-bolder">*/}
                                            {/*    Register Face*/}
                                            {/*</h1>*/}
                                            <p>Register your face against your Employee ID to generate Face Id</p>

                                            <div className="row px-2">
                                                <TextField id="employeeIdInput" label="Employee ID" type={"text"}
                                                           variant="outlined"
                                                           disabled={isInputEnabled != "true"}
                                                           value={employeeId} onChange={handleEmployeeIdChange}
                                                           className="full-width my-2"/>
                                                <Button variant="contained" className={classes.bgGradient + ' my-2 py-3'}
                                                        onClick={() => {
                                                            onClickRegisterButton()
                                                        }}>
                                                    Register
                                                </Button>

                                                {/*<p className="my-2">*/}
                                                {/*    Already have an account?&nbsp;*/}
                                                {/*    <span className="fw-bolder text-theme-dark cursor-pointer"*/}
                                                {/*          onClick={() => navigate('/login')}>*/}
                                                {/*    Login*/}
                                                {/*</span>*/}
                                                {/*</p>*/}
                                            </div>
                                        </div>
                                    }


                                    {showWebcam &&
                                        <div className="my-2">
                                            {/*<h1 className="mb-3 fw-bolder">*/}
                                            {/*    Register Face*/}
                                            {/*</h1>*/}
                                            <p className="">
                                                Registering your face. Please stop training after a minute.
                                            </p>

                                            <div className="row">

                                                <Webcam
                                                    ref={webcamRef}
                                                    className="img-fluid text-center"
                                                    mirrored
                                                    screenshotFormat="image/jpeg"
                                                />
                                                <img className={classes.inputImage} alt="input" ref={imgRef}/>


                                                <div className="mt-1">
                                                    <LinearProgress variant="determinate" value={progress}/>

                                                    <Button variant="contained"
                                                            className={classes.bgGradient + ' my-3 py-3 full-width'}
                                                            onClick={() => {
                                                                onClickRegisterButton()
                                                            }}>
                                                        Stop
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {isFaceRegistered &&
                                <div className={'text-center'}>
                                    <img className="img-fluid text-center mx-auto my-3" style={{maxWidth: '256px'}}
                                         src={require('../../../../assets/illustration/face-detection.png')}/>
                                    <h4>Face Registered</h4>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                {/* Hero Div */}
                {/*<div className="col-md-6">*/}
                {/*    {showWebcam &&*/}
                {/*        <div className="d-flex align-items-center justify-content-center h-100"*/}
                {/*             style={{backgroundColor: '#000'}}>*/}
                {/*            <div className="full-width text-center">*/}
                {/*                <img className="text-center img-fluid"*/}
                {/*                     src={"https://cdn.dribbble.com/users/32512/screenshots/6495831/face_id2_effect_by_gleb.gif"}*/}
                {/*                     alt="asd"/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    }*/}

                {/*    {!showWebcam &&*/}
                {/*        <div className="">*/}
                {/*            <Card sx={{m: 3, minHeight: '95vh', boxShadow: 10}} className={classes.bgGradient}>*/}
                {/*                <div className="d-flex align-items-center justify-content-center"*/}
                {/*                     style={{minHeight: '95vh'}}>*/}
                {/*                    <img className="img-fluid px-3"*/}
                {/*                         src={require('../../../../assets/logo/logo-white.png')}/>*/}
                {/*                </div>*/}
                {/*            </Card>*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*</div>*/}

            </div>

            {/* Success Alert */}
            <Snackbar open={openSuccessAlert}
                      autoHideDuration={6000}
                      onClose={handleCloseAlert}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert severity="success" onClose={handleCloseAlert} sx={{width: '100%'}}>
                    <AlertTitle>Face Registered</AlertTitle>
                    An email has been sent to you regarding the next steps
                </Alert>
            </Snackbar>

            {/* Error Alert */}
            <Snackbar open={openErrorAlert}
                      autoHideDuration={6000}
                      onClose={handleCloseAlert}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Alert severity="error" onClose={handleCloseAlert} sx={{width: '100%'}}>
                    <AlertTitle>Account Creation Failed</AlertTitle>
                    Please try again later
                </Alert>
            </Snackbar>
        </section>
    );
}


export default RegisterFace;
