import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll, postTCH} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import BankdetailForm from "../../Bankdetail/BankdetailForm/BankdetailForm";
import BankdetailCreate from "../../Bankdetail/BankdetailCreate/BankdetailCreate";
import AudioRecorder from '../../../Common/AudioRecorder/AudioRecorder';
import SalesSimulation from '../../SalesSimulation/SalesSimulation';

const steps = ['Script Reading', 'Sales Simulation', 'Interview'];


const ApplicationFormSimulations = () => {
        // css for module
        const classes = useStyles();

        // api modules
        const moduleGetQuestionTypes = 'GetQuestionTypes';
        const moduleGetQuestions = 'GetQuestions';
        const moduleSaveAnswers = 'SaveAnswer';

        // router object
        const navigate = useNavigate();

        // translation
        const {t} = useTranslation();

        // UI methods
        const [loading, setLoading] = React.useState(true);
        const [status, setStatus] = React.useState('2');

        // data vars
        const [activeStep, setActiveStep] = React.useState(0);
        const [skipped, setSkipped] = React.useState(new Set<number>());
        const [questions, setQuestions] = React.useState([]);
        const [questionTypes, setQuestionTypes] = React.useState([]);

        // form data
        const {register, handleSubmit, watch, reset, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")
        const [watchData, setWatchData] = React.useState({});

        // form methods
        const onSubmit = (_data: any) => {
            // post interview answers
            Object.keys(_data.interview).forEach((_key: string) => {
                const payload = {
                    questionID: _key.split('-')[1],
                    answer: _data.interview._key,
                    useID: 7553,
                    method: moduleSaveAnswers
                }

                postTCH(payload)
                    .then((_res: any) => {
                        setLoading(false); // hide loader
                    })
                    .catch(_err => {;
                        setLoading(false); // hide loader
                    })
            });
        }

        const onCancel = () => {
            // handleCancelEvent();
        }

        // ui handlers
        const handleChange = (
            event: React.MouseEvent<HTMLElement>,
            newAlignment: string,
        ) => {
            setStatus(newAlignment);
        };

        const getQuestionTypeName = (_id: string) => {
            let name = '';
            questionTypes.forEach((_type: any) => {
                if (_type.questionTypeID == _id) {
                    name = _type.questionType;
                }
            });
            return name;
        }

        // stepper methods
        const isStepOptional = (step: number) => {
            return step === 1;
        };

        const isStepSkipped = (step: number) => {
            return skipped.has(step);
        };

        const handleNext = () => {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        const handleSkip = () => {
            if (!isStepOptional(activeStep)) {
                // You probably want to guard against something like this,
                // it should never occur unless someone's actively trying to break something.
                throw new Error("You can't skip a step that isn't optional.");
            }

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped((prevSkipped) => {
                const newSkipped = new Set(prevSkipped.values());
                newSkipped.add(activeStep);
                return newSkipped;
            });
        };

        const handleReset = () => {
            setActiveStep(0);
        };


        const fetch = () => {
            fetchQuestions();
            fetchQuestionTypes();
        }

        const fetchQuestionTypes = () => {
            setLoading(true); // show loader
            postTCH({
                method: moduleGetQuestionTypes
            })
                .then((_res: any) => {
                    setQuestionTypes(_res)
                    setLoading(false); // hide loader
                })
                .catch(_err => {
                    console.log(_err.response.data);
                    setLoading(false); // hide loader
                })
        }

        const fetchQuestions = () => {
            setLoading(true); // show loader
            postTCH({
                method: moduleGetQuestions
            })
                .then((_res: any) => {
                    var rawDataObj = _res.replace('[', '').replace(']', '').split('},');
                    var dataObj: any = [];
                    rawDataObj.forEach((_obj: string, _idx: number) => {
                        _obj += '}';
                        try {
                            dataObj.push(JSON.parse(_obj));
                        } catch (e) {
                        }
                    })

                    setQuestions(dataObj);
                    setLoading(false); // hide loader
                })
                .catch(_err => {
                    console.log(_err);
                    setLoading(false); // hide loader
                })
        }


        // hooks
        useEffect(() => {
            fetch();
        }, []);


        return (
            <div>
                <Box sx={{width: '100%'}}>
                    <Card className={'px-2'}>

                        {/* Stepper Head */}
                        <div className="py-3">
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>


                        {/* Body Content */}
                        {activeStep === steps.length ? (
                            // Last Step
                            <React.Fragment>

                                <div className={'row pb-2 mx-2'}>
                                    <div className="col-md-12 my-2">
                                        {/* Form name */}
                                        <h4 className="mb-4">
                                            Registration Complete
                                        </h4>
                                        <p>Your Application ID is <span className={'text-success fw-bold'}> 2123 </span></p>
                                        <p>
                                            Well done! your registration is now complete. We will be in contact with you
                                            over
                                            email within 48 hours. Should you have any questions, please contact us and use
                                            the
                                            below reference number.
                                        </p>
                                        <p>
                                            Have a wonderful day.
                                        </p>
                                    </div>
                                </div>


                                {/*<Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>*/}
                                {/*    <Box sx={{flex: '1 1 auto'}}/>*/}
                                {/*    <Button onClick={handleReset}>Reset</Button>*/}
                                {/*</Box>*/}
                            </React.Fragment>
                        ) : (
                            // Oter Steps
                            <React.Fragment>
                                <form className="row p-0 m-0" onSubmit={handleSubmit(onSubmit)}>

                                    {/* Script Reading Step */}
                                    {activeStep == 0 &&
                                        <div className={'row pb-2'}>
                                            <div className="col-md-12 my-2">
                                                {/* Form name */}
                                                <h4 className="">
                                                    Let's simulate reading a script
                                                </h4>
                                            </div>

                                            <div className="col-md-12">
                                                <Alert severity="warning">
                                                    Once you have allowed access to your microphone
                                                    click the 'record' button and read the script out as best you can.
                                                    Once you're done, dick the 'Stop' button.
                                                    (You will need to plug in headphones with a mic for this process)
                                                </Alert>

                                                <div className="my-5">
                                                    <AudioRecorder/>
                                                </div>

                                                <div>
                                                    <h5 className={'mb-3'}>
                                                        Script
                                                    </h5>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                    {/* Sales Simulation Step */}
                                    {activeStep == 1 &&
                                        <div className={'row pb-2'}>
                                            <div className="col-md-12 my-2">
                                                {/* Form name */}
                                                <h4 className="">
                                                    You will now go through a simulate sales test
                                                </h4>
                                            </div>

                                            <div className="col-md-12">
                                                <Alert severity="warning">
                                                    Please press play on the provided recording, listen carefully and fill
                                                    out the necessary fields with the correct client information.
                                                </Alert>

                                                <div className="my-5">
                                                    <SalesSimulation/>
                                                </div>

                                                <div>
                                                    <h5 className={'mb-3'}>
                                                        Questions
                                                    </h5>

                                                    {/* Questions */}
                                                    <div className="row">
                                                        {/* Field Question */}
                                                        <div className="col-md-6 mt-4">
                                                            <FormControl fullWidth>
                                                                <TextField id="question1"
                                                                           {...register("question1", {required: true})}
                                                                           label={'Question 1'}
                                                                           type={"text"}
                                                                           error={!!errors.question1}
                                                                           variant="outlined"
                                                                           className="full-width"/>
                                                            </FormControl>
                                                        </div>

                                                        {/* Field Question */}
                                                        <div className="col-md-6 mt-4">
                                                            <FormControl fullWidth>
                                                                <TextField id="question2"
                                                                           {...register("question2", {required: true})}
                                                                           label={'Question 2'}
                                                                           type={"text"}
                                                                           error={!!errors.question2}
                                                                           variant="outlined"
                                                                           className="full-width"/>
                                                            </FormControl>
                                                        </div>

                                                        {/* Field Question */}
                                                        <div className="col-md-6 mt-4">
                                                            <FormControl fullWidth>
                                                                <TextField id="question3"
                                                                           {...register("question3", {required: true})}
                                                                           label={'Question 3'}
                                                                           type={"text"}
                                                                           error={!!errors.question3}
                                                                           variant="outlined"
                                                                           className="full-width"/>
                                                            </FormControl>
                                                        </div>

                                                        {/* Field Question */}
                                                        <div className="col-md-6 mt-4">
                                                            <FormControl fullWidth>
                                                                <TextField id="question4"
                                                                           {...register("question4", {required: true})}
                                                                           label={'Question 4'}
                                                                           type={"text"}
                                                                           error={!!errors.question4}
                                                                           variant="outlined"
                                                                           className="full-width"/>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    }

                                    {/* Interview Step */}
                                    {activeStep == 2 &&
                                        <div className={'row pb-2'}>
                                            <div className="col-md-12 my-2">
                                                {/* Form name */}
                                                <h4 className="">
                                                    Interview
                                                </h4>
                                            </div>

                                            <div className="col-md-12">
                                                {/* Questions */}
                                                <div>
                                                    <div className="row">

                                                        <div>
                                                            {questions.map((q: any) => {
                                                                    if (getQuestionTypeName(q.questionType) == 'Text') {
                                                                        return (
                                                                            // text type question
                                                                            <div className="row">
                                                                                {/*Field Question*/}
                                                                                <div className="col-md-6 mt-4">
                                                                                    <p>
                                                                                        {q.question}
                                                                                    </p>
                                                                                </div>

                                                                                {/* Field Answer */}
                                                                                <div className="col-md-6 mt-4">
                                                                                    <FormControl fullWidth>
                                                                                        <TextField id="question1"
                                                                                                   {...register("interview.interview-" + q.questionID, {required: true})}
                                                                                                   label={'Answer'}
                                                                                                   type={"text"}
                                                                                                   multiline
                                                                                                   maxRows={4}
                                                                                                   minRows={2}
                                                                                                   error={!!errors.question1}
                                                                                                   variant="outlined"
                                                                                                   className="full-width"/>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    } else if (getQuestionTypeName(q.questionType) == 'Number') {
                                                                        return (
                                                                            // number type question
                                                                            <div className="row">
                                                                                {/*Field Question*/}
                                                                                <div className="col-md-6 mt-4">
                                                                                    <p>
                                                                                        {q.question}
                                                                                    </p>
                                                                                </div>

                                                                                {/* Field Answer */}
                                                                                <div className="col-md-6 mt-4">
                                                                                    <FormControl fullWidth>
                                                                                        <TextField id="question1"
                                                                                                   {...register("question1", {required: true})}
                                                                                                   label={'Answer'}
                                                                                                   type={"number"}
                                                                                                   error={!!errors.question1}
                                                                                                   variant="outlined"
                                                                                                   className="full-width"/>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    }


                                    <div className="py-2">
                                        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{mr: 1}}
                                            >
                                                Back
                                            </Button>
                                            <Box sx={{flex: '1 1 auto'}}/>
                                            {/*<Button onClick={handleNext}>*/}
                                            {/*    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}*/}
                                            {/*</Button>*/}

                                            { activeStep !== steps.length - 1 &&
                                                <Button onClick={handleNext}>
                                                    Next
                                                </Button>
                                            }

                                            { activeStep === steps.length - 1 &&
                                                <LoadingButton
                                                               type="submit"
                                                               variant="contained"
                                                               className="bg-custom-gradient">
                                                   Save
                                                </LoadingButton>
                                            }

                                        </Box>
                                    </div>

                                </form>
                            </React.Fragment>
                        )}
                    </Card>
                </Box>
            </div>
        );
    }
;

export default ApplicationFormSimulations;
