// @ts-nocheck

import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {Button, Card, CardContent, TextField, Typography} from "@mui/material";
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useStyles} from './Styles';
import '../../../../index.scss';

const MicRecorder = require('mic-recorder-to-mp3');

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

const AudioRecorder = forwardRef((props, ref) =>{

    // call method from parent
    useImperativeHandle(ref, () => ({
        getBlob() {
            return blob;
        }
    }));


    // css for module
    const classes = useStyles();

    // decalre mic recorder object
    const [Mp3Recorder, setMp3Recorder] = React.useState(
        new MicRecorder({bitRate: 128})
    );
    

    // data vars
    const [isRecording, setIsRecording] = React.useState(false);
    const [blobURL, setBlobURL] = React.useState('');
    const [blob, setBlob] = React.useState('');
    const [audio, setAudio] = React.useState(null);
    const [isBlocked, setIsBlocked] = React.useState(false);
    const [hasRecordedOnce, setHasRecordedOnce] = React.useState(false); 
    const [isPlaying, setIsPlaying] = React.useState(false);

    useEffect(() => {
        // get mic permissions
        navigator.getUserMedia = (
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia
        );
        // @ts-ignore
        navigator.getUserMedia({audio: true},
            () => {
                console.log('Permission Granted');
                setIsBlocked(false);
            },
            () => {
                console.log('Permission Denied');
                setIsBlocked(true);
            },
        );
    }, [])

    // start recording
    const start = () => {
        if (isBlocked) {
            console.log('Permission Denied');
        } else {
            Mp3Recorder
                .start()
                .then(() => {
                    setIsRecording(true);
                }).catch((_error: any) => console.error(_error));
        }
    };

    // stop recording
    const stop = () => {
        Mp3Recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const blobURL = URL.createObjectURL(blob);
                setBlobURL(blobURL);
                setBlob(blob);
                const audioElement = new Audio(blobURL);
                setAudio(audioElement);
                setHasRecordedOnce(true);
                setIsRecording(false);
                audioElement.onended = () => {
                    setIsPlaying(false);
                };
               
            }).catch((_error) => console.error(_error));
    };
    const play = () => {
        if (audio) {
            audio.play();
            setIsPlaying(true);
        }
      
    };

    return (
        <div className={'container'}>
            {/* Recorder Div */}
            <div className="row px-3 mb-3">
                <div className="col-12 col-md-8 text-center">
                {!isRecording && blobURL && (
                        isPlaying ? (
                            <img src={'https://rtionline.gov.in/sound_200.gif'} className={'img-fluid'} width={'50px'} />
                        ) : (
                            <Button variant="contained" onClick={play} fullWidth size={'large'}>
                                Play
                            </Button>
                        )
                    )}
                    {isRecording &&
                        <img src={'https://rtionline.gov.in/sound_200.gif'} className={'img-fluid'} width={'50px'}/>
                    }
                </div>
                <div className="col-12 col-md-4 pt-1">
                    {!isRecording && !hasRecordedOnce &&
                        <Button variant="contained" onClick={start} disabled={isRecording} fullWidth size={'large'}>
                            Record
                        </Button>
                    }
                    {isRecording &&
                        <Button variant="outlined" onClick={stop} disabled={!isRecording} fullWidth size={'large'}>
                            Stop
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
});


export default AudioRecorder;
