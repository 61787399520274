// @ts-nocheck

import React, {useRef} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {post} from "../../../../../Services/GenericApiService";
import {useTranslation} from "react-i18next";
import ApplicationDocumentsForm from "../ApplicationDocumentsForm/ApplicationDocumentsForm";
import {log} from "../../../../../Services/LoggerService";
import { useLocation } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import FileUploadM from '../../../../Helpers/FileUploadM/FileUploadM';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import {Button, IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)


// @ts-ignore
function ApplicationDocumentsCreate({refreshDataEvent}) {
    // css for module
    const classes = useStyles();

    // Routing data
    const location = useLocation();

    // module(s) for api
    const moduleMain = 'application';
    const moduleBank = 'bankdetail';

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // handle add record event
    const handleAddEvent = (_formData: any) => {
        setLoading(true); // show loader
        // create bank details
        post(moduleBank, _formData)
            .then(_res => {
                _formData.bankDetailsId = _res.id;
                post(moduleMain, _formData)
                    .then(_resApplicant => {
                        setLoading(false); // hide loader
                        refreshDataEvent(); // refresh data
                        handleClose();
                        // @ts-ignore
                        notifications.current.successAlert(t('common.createSuccess'), '');
                    })
                    .catch(_err => {
                        log(_err)
                        setLoading(false); // hide loader
                        // @ts-ignore
                        notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                    })
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }


    return (
        <div>

            <Tooltip title={t('common.add')}>
                <IconButton aria-label="add" size="small">
                    <FontAwesomeIcon className="edit-btn" icon='plus' onClick={handleOpen} />
                </IconButton>
            </Tooltip>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={classes.modalScroll}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        {/*<ApplicationDocumentsForm handleAddEvent={handleAddEvent}*/}
                        {/*                 isEditMode={false}*/}
                        {/*                 handleEditEvent={() => {*/}
                        {/*                 }}*/}
                        {/*                 handleCancelEvent={handleCancelEvent}*/}
                        {/*                 recordToUpdate={null}*/}
                        {/*                 isLoading={loading}/>*/}
                        <FileUploadM />
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
}

export default ApplicationDocumentsCreate;
