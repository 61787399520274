// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    inputImage: {
        display: 'none'
    },
    bgGradient: {
        background: 'rgb(0,91,164)',
        background: '-moz-linear-gradient(50deg, rgba(0,91,164,1) 0%, rgba(1, 143, 141,1) 50%, rgba(3, 217, 107,1) 100%)',
        background: '-webkit-linear-gradient(50deg, rgba(0,91,164,1) 0%, rgba(1, 143, 141,1) 50%, rgba(3, 217, 107,1) 100%)',
        background: 'linear-gradient(50deg, rgba(0,91,164,1) 0%, rgba(1, 143, 141,1) 50%, rgba(3, 217, 107,1) 100%)',
        filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#3cb7d0",GradientType=1)'
    }
});

export { useStyles }
