import React from 'react';
import {useStyles} from './Styles';
import {Box, Button, Card, CardContent, TextField, Typography} from "@mui/material";
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "../../Layout/Header/Header";
import BasicDropzoneM from './Dropzone/BasicDropzoneM';

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

function FileUploadM() {
    // css for module
    const classes = useStyles();


    return (
        <Box sx={{display: 'flex', overflowY: "scroll"}}>
            <Card sx={{minWidth: 475}}>
                <div className="my-5 px-md-3 px-2">
                    <BasicDropzoneM />
                </div>
            </Card>
        </Box>
    );
}


export default FileUploadM;
