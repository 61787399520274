// @ts-nocheck

import React, { useEffect, useRef, createRef } from "react";
import { useStyles } from "./Styles";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {
  post,
  getAll,
  postTCH,
} from "../../../../../Services/GenericApiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import AlertM from "../../../../Helpers/AlertM/AlertM";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import {
  Skeleton,
  Autocomplete,
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  FormLabel,
} from "@mui/material";
import InputMask from "react-input-mask";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import BankdetailForm from "../../Bankdetail/BankdetailForm/BankdetailForm";
import BankdetailCreate from "../../Bankdetail/BankdetailCreate/BankdetailCreate";
import AudioRecorder from "../../../Common/AudioRecorder/AudioRecorder";
import SalesSimulation from "../../SalesSimulation/SalesSimulation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

// font awesome stuff
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const steps = [
  "Disclaimer",
  "Basic Info",
  "Bank Info",
  "Script Reading",
  "Sales Simulation",
  "Interview",
];

// @ts-ignore
function ApplicationForm({
  handleAddEvent,
  handleCancelEvent,
  deviceId = "",
  isLoading,
  isEditMode,
  recordToUpdate,
  handleEditEvent,
}) {
  // css for module
  const classes = useStyles();

  // router object
  const navigate = useNavigate();

  // module(s) for api
  const moduleMain = "application";
  const moduleEducation = "education";
  const moduleGetTitlesTCH = "GetTitles";
  const moduleGetBanksTCH = "GetBanks";
  const moduleGetBankAccountTypes = "GetAccountTypes";
  const moduleGetQuestionTypes = "GetQuestionTypes";
  const moduleGetQuestions = "GetQuestions";
  const moduleSaveAnswers = "SaveAnswer";
  const moduleAddUserTCH = "AddUser";
  const moduleCompleteProfileTCH = "CompleteProfile";
  const moduleSaveSalesSimulationTCH = "SaveSalesSimulation";
  const moduleValidateBankTCH = "ValidateBank";
  const moduleSaveScriptSimulation = "scriptsimulation";
  const moduleSaveInterviewAudio = "interviewaudio";
  const moduleDevice = "device";

  // translation
  const { t } = useTranslation();

  // child ref for alert
  const notifications = useRef();
  // UI vars
  const [hasBankAccount, setHasBankAccount] = React.useState(false);
  const [hasTaxID, setHasTaxID] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState("2"); // 0 1 2 for pending approved and reject
  const [isReadingScriptRecorded, setIsReadingScriptRecorded] =
    React.useState(false);
  const [numberOfAnswersRecorded, setNumberOfAnswersRecorded] = React.useState(
    {}
  );
  const [isRecording, setIsRecording] = React.useState(false);
  const [indexRecording, setIndexRecording] = React.useState(0);
  const [isValid, setIsValid] = React.useState(true);
  const [validError, setValidError] = React.useState("");
  // data vars
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [empId, setEmpId] = React.useState(null);
  const scriptSimulationAudioBlobRef = useRef();
  const [interviewAudioRefs, setInterviewAudioRefs] = React.useState([]);
  const [isBiometricsValidated, setIsBiometricsValidated] =
    React.useState(null);
    const [isChecked, setIsChecked] = React.useState(false);
  // form data
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm(); // watch is used to get the value e.g watch("name")
  const [watchData, setWatchData] = React.useState({});

  // form methods
  const onSubmit = (_data: any) => {
    if (!isEditMode) {
      handleAddEvent(_data);
    } else {
      _data.status = status;
      console.log(_data);
      handleEditEvent(_data);
    }
  };

  const onCancel = () => {
    handleCancelEvent();
  };

  // ui handlers
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setStatus(newAlignment);
  };

  const handleTaxIDToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setHasTaxID(newAlignment === "yes");
    }
  };

  const handleBankAccountToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setHasBankAccount(newAlignment === "yes");
    }
  };

  const getQuestionTypeName = (_id: string) => {
    let name = "";
    questionTypes.forEach((_type: any) => {
      if (_type.questionTypeID == _id) {
        name = _type.questionType;
      }
    });
    return name;
  };

  // fetch dependent data
  const [allTitle, setAllTitle] = React.useState([]);
  const [allBanks, setAllBanks] = React.useState([]);
  const [allBankAccountTypes, setAllBanksAccountTypes] = React.useState([]);
  const [allEducation, setAllEducation] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [questionTypes, setQuestionTypes] = React.useState([]);

  const fetch = () => {
    fetchTitle();
    fetchBankdetail();
    fetchBankAccountTypes();
    fetchEducation();
    fetchQuestions();
    fetchQuestionTypes();
  };

  // fetch Title
  const fetchTitle = () => {
    setLoading(true); // show loader
    postTCH({
      method: moduleGetTitlesTCH,
    })
      .then((_res: any) => {
        setAllTitle(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        console.log(_err.response.data);
        setLoading(false); // hide loader
      });
  };

  // fetch Bankdetail
  const fetchBankdetail = () => {
    setLoading(true); // show loader
    postTCH({
      method: moduleGetBanksTCH,
    })
      .then((_res: any) => {
        // replace key 'bank' with 'label'
        _res = JSON.parse(
          JSON.stringify(_res).split('"bank":').join('"label":')
        );
        // replace key 'bankID' with 'id'
        _res = JSON.parse(
          JSON.stringify(_res).split('"bankID":').join('"id":')
        );
        setAllBanks(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        console.log(_err.response.data);
        setLoading(false); // hide loader
      });
  };

  // fetch Education
  const fetchEducation = () => {
    setLoading(true); // show loader
    getAll(moduleEducation)
      .then((_res: any) => {
        setAllEducation(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        console.log(_err.response.data);
        setLoading(false); // hide loader
      });
  };

  // fetch account types
  const fetchBankAccountTypes = () => {
    setLoading(true); // show loader
    postTCH({
      method: moduleGetBankAccountTypes,
    })
      .then((_res: any) => {
        setAllBanksAccountTypes(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        console.log(_err.response.data);
        setLoading(false); // hide loader
      });
  };

  // fetch question types
  const fetchQuestionTypes = () => {
    setLoading(true); // show loader
    postTCH({
      method: moduleGetQuestionTypes,
    })
      .then((_res: any) => {
        setQuestionTypes(_res);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        console.log(_err.response.data);
        setLoading(false); // hide loader
      });
  };

  // fetch interview questions
  const fetchQuestions = () => {
    setLoading(true); // show loader
    postTCH({
      method: moduleGetQuestions,
    })
      .then((_res: any) => {
        var dataObj: any = [];
        _res.forEach((_obj: string, _idx: number) => {
          try {
            _obj.question = _obj.question.replace(/<\s*\/?br\s*[\/]?>/gi, "\n"); // replace br tags with new line <br> <br/> <BR>
            dataObj.push(_obj);
          } catch (e) {}
        });
        setQuestions(dataObj);
        setLoading(false); // hide loader
      })
      .catch((_err) => {
        console.log(_err);
        setLoading(false); // hide loader
      });
  };

  // stepper methods
  const isStepOptional = (step: number) => {
    return step === 3 || step === 4;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    console.log("device id: ", deviceId);
    switch (activeStep) {
        // Disclaimer
        case 0:
            // nothing to post here. Just increment.
            incrementStep();
            break;
      // basic info
      case 1:
        // nothing to post here. Just increment.
        incrementStep();
        break;

      // bank info
      case 2:
        const payload = watch();
        payload.method = moduleAddUserTCH;

        postTCH(payload)
          .then((_res) => {
            setEmpId(_res.userID);
            incrementStep();
          })
          .catch((_err) => {
            log(_err);
          });

        break;

      // script reading
      case 3:
        // instantiate a file reader
        const fileReader = new FileReader();

        // read the file
        fileReader.readAsDataURL(
          scriptSimulationAudioBlobRef.current.getBlob()
        );

        fileReader.onloadend = function () {
          const b64 = fileReader.result;
          post(moduleSaveScriptSimulation, {
            employeeId: empId,
            audio: b64,
          })
            .then((_res: any) => {
              console.log(_res);
            })
            .catch((_error) => {
              log(_error);
            });
        };

        incrementStep();
        break;

      // sales simulation
      case 4:
        const payloadSS = watch("sales");
        // add method
        payloadSS.method = moduleSaveSalesSimulationTCH;
        // add emp id
        payloadSS.useID = empId;

        // post
        postTCH(payloadSS)
          .then((_res: any) => {
            console.log(_res);
          })
          .catch((_e: any) => {
            console.log(_e);
          });
        incrementStep();
        break;

      // interview
      case 5:
        questions.forEach((_q, _i) => {
          // post audio type questions
          if (
            getQuestionTypeName(_q.questionType) == "Voice Recording" &&
            interviewAudioRefs[_i].current.getBlob().size !== undefined
          ) {
            // instantiate a file reader
            const fileReader = new FileReader();

            // read the file
            fileReader.readAsDataURL(interviewAudioRefs[_i].current.getBlob());

            fileReader.onloadend = function () {
              const b64 = fileReader.result;
              post(moduleSaveInterviewAudio, {
                employeeId: empId,
                audio: b64,
                questionId: _q.questionID,
              })
                .then((_res: any) => {
                  console.log(_res);
                })
                .catch((_error) => {
                  log(_error);
                });
            };
          }
          // post text type questions
          else if (getQuestionTypeName(_q.questionType) == "Text") {
            const answer = watch("interview")["interview-" + _q.questionID];

            const payload = {
              method: moduleSaveAnswers,
              questionID: _q.questionID,
              answer: answer,
              useID: empId,
            };

            postTCH(payload)
              .then((_res: any) => {
                console.log(_res);
              })
              .catch((_err) => {
                console.log(_err.response.data);
              });
          }
        });
        incrementStep();

        // complete profile
        postTCH({
          method: moduleCompleteProfileTCH,
          useID: empId,
          Mobile_number: watch("Mobile_number"),
        })
          .then((_res: any) => {
            console.log(_res);

            //
            const devicePayload = {
              empId,
              deviceId,
              onboardingCompleted: true,
            };
            post(moduleDevice, devicePayload)
              .then((_resp: any) => {
                console.log(_resp);
              })
              .catch((_err) => {
                console.log(_err.response.data);
              });
          })
          .catch((_error) => {
            console.log(_error.response.data);
          });

        break;
    }
  };

  const redirectToWebSystemURL = () => {
    window.location.href = `https://italk2u.bbarray.com/`;
  };

  const incrementStep = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    validateNextButton();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const displayName = () => {
    const selectedTitle =
      allTitle.filter((_item) => _item.titleID == watch("Title"))[0].title ||
      "";
    return selectedTitle + " " + watch("Name") + " " + watch("Surname");
  };

  const recordAnswerCount = (_questionId) => {
    if (_questionId in numberOfAnswersRecorded) {
      numberOfAnswersRecorded[_questionId] += 1;
      setNumberOfAnswersRecorded({ ...numberOfAnswersRecorded });
    } else {
      numberOfAnswersRecorded[_questionId] = 0;
      setNumberOfAnswersRecorded({ ...numberOfAnswersRecorded });
    }
  };

  const validateNextButton = () => {
    switch (activeStep) {
        case 0:
            if (!isChecked){
                return false;
            }
      case 1:
        if (
          watch("Surname") == "" ||
          watch("Email_Address") == "" ||
          watch("Highest_Qualification") == "" ||
          watch("Mobile_number") == "" ||
          watch("Name") == "" ||
          (hasTaxID && watch("Tax_Number") == "") ||
          watch("Title") == "" ||
          watch("ID_Number") == ""
        ) {
          return false;
        }
        break;

      case 2:
        if (hasBankAccount) {
          if (
            watch("Bank") == "" ||
            !watch("Bank") ||
            watch("Bank_Account_Holder") == "" ||
            !watch("Bank_Account_Holder") ||
            watch("Bank_Account_Type") == "" ||
            !watch("Bank_Account_Type")
          ) {
            return false;
          }
        }
        break;

      case 3:
        if (!isReadingScriptRecorded) {
          return false;
        }
        break;

      case 4:
        if (
          watch("sales.SStitle") == undefined ||
          watch("sales.SStitle") == "" ||
          watch("sales.SSname") == undefined ||
          watch("sales.SSname") == "" ||
          watch("sales.SSSurname") == undefined ||
          watch("sales.SSSurname") == "" ||
          watch("sales.SSIDNumber") == undefined ||
          watch("sales.SSIDNumber") == "" ||
          watch("sales.SSgrossincome") == undefined ||
          watch("sales.SSgrossincome") == "" ||
          watch("sales.SSemailaddress") == undefined ||
          watch("sales.SSemailaddress") == "" ||
          watch("sales.SSaddress") == undefined ||
          watch("sales.SSaddress") == "" ||
          watch("sales.SScity") == undefined ||
          watch("sales.SScity") == "" ||
          watch("sales.SSsuburb") == undefined ||
          watch("sales.SSsuburb") == "" ||
          watch("sales.SSprovince") == undefined ||
          watch("sales.SSprovince") == ""
        ) {
          return false;
        }
        break;

      case 5:
        const answers = watch("interview");

        let isValid = true;
        // iterate over all questions
        questions.forEach((_question) => {
          // check question by type
          switch (getQuestionTypeName(_question.questionType)) {
            case "Voice Recording":
              if (
                !(_question.questionID in numberOfAnswersRecorded) ||
                numberOfAnswersRecorded[_question.questionID] == 0
              ) {
                isValid = false;
              }
              break;
            case "Text":
              if (answers["interview-" + _question.questionID] == "") {
                isValid = false;
              }
              break;
          }
        });

        return isValid;
        break;
    }
    return true;
  };

  // hooks
  useEffect(() => {
    fetch();
    if (isEditMode) {
      recordToUpdate = Object.assign(
        recordToUpdate,
        recordToUpdate.applicantBank
      );
      reset(recordToUpdate);
      setWatchData(watch());
    }
  }, []);

  useEffect(() => {
    // add or remove refs
    setInterviewAudioRefs((elRefs) =>
      Array(questions.length)
        .fill()
        .map((_, i) => interviewAudioRefs[i] || createRef())
    );

    console.log(interviewAudioRefs);
  }, [questions.length]);

  return (
    <div>
      <Box
        sx={{ width: "100%", maxHeight: "95vh!important", overflowY: "auto" }}
      >
        <Card className={"px-2"}>
          {/* Stepper Head */}
          <div className="row w-100">
            <div className="col-12">
              <div className="py-3">
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
            </div>
          </div>

          {/* Body Content */}
          {activeStep === steps.length ? (
            // Last Step
            <React.Fragment>
              <div className={"row pb-2 mx-2"}>
                <div className="col-md-12 my-2">
                  {/* Form name */}
                  <h4 className="mb-4">Registration Complete</h4>
                  <p>
                    Your Application is{" "}
                    <span className={"text-success fw-bold"}> {empId} </span>
                  </p>
                  <p>
                    Well done {displayName()}! your registration is now
                    complete. We will be in contact with you over email within
                    48 hours. Should you have any questions, please contact us
                    and use the below reference number.
                  </p>
                  <p>Have a wonderful day.</p>
                </div>
              </div>
              <div className="row">
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <div className="ms-auto m-2">
                    <Button onClick={redirectToWebSystemURL}>Done</Button>
                  </div>
                </Box>
              </div>

              {/*<Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>*/}
              {/*    <Box sx={{flex: '1 1 auto'}}/>*/}
              {/*    <Button onClick={handleReset}>Reset</Button>*/}
              {/*</Box>*/}
            </React.Fragment>
          ) : (
            // Oter Steps
            <React.Fragment>
              <form className="row p-0 m-0" onSubmit={handleSubmit(onSubmit)}>
                {/* Script Reading Step */}
                {activeStep == 0 && (
                  <div className={"row pb-2"}>
                    <div className="col-md-12 my-2">
                      {/* Form name */}
                      <h4 className="">Disclaimer</h4>
                    </div>

                    <div className="col-md-12">
                      <div>
<p>This Electronic Disclaimer Agreement is entered into between ITalk (&ldquo;the Company&rdquo;) and you (&ldquo;the User&rdquo;) effective as of the date of the User&rsquo;s initial login to the ITalk System.&nbsp;</p>
<p>This disclaimer sets forth the general guidelines, terms, and conditions of your learnership application and use of our recruitment services (&quot;Services&quot;). By ticking the box below and submitting your application, you acknowledge and agree to the terms and conditions outlined in this Disclaimer.</p>
<p><strong>Protection of Personal Information Act (POPI) Compliance</strong></p>
<p>By submitting your application, you acknowledge and agree that the Company will process your personal information in compliance with the Protection of Personal Information Act, 2013 (Act No. 4 of 2013). The relevant sections of the POPI Act that the Company will comply with include, but are not limited to:</p>
<ol>
    <li><strong>Section 9 (Lawfulness of Processing)</strong>: Personal information will be processed lawfully and in a reasonable manner that does not infringe the privacy of the data subject.</li>
    <li><strong>Section 10 (Minimality)</strong>: The Company will only process personal information that is relevant and necessary for the purpose of recruitment and the learnership application process.</li>
    <li><strong>Section 11 (Consent, Justification, and Objection)</strong>: Personal information will be processed with your consent, and you have the right to object to the processing of your personal information.</li>
    <li><strong>Section 14 (Retention and Restriction of Records)</strong>: Personal information will not be retained for longer than is necessary for achieving the purpose for which it was collected unless retention is required or authorized by law.</li>
    <li><strong>Section 19 (Security Safeguards)</strong>: The Company will secure the integrity and confidentiality of personal information by taking appropriate, reasonable technical and organizational measures to prevent loss, damage, unauthorized destruction, and unlawful access to or processing of personal information.</li>
</ol>
<p><strong>Storing Personal Information</strong></p>
<p>You consent to the Company securely storing your personal information in accordance with the following guidelines:</p>
<ul>
    <li><strong>Data Encryption</strong>: All personal information will be encrypted both in transit and at rest to protect it from unauthorized access.</li>
    <li><strong>Access Control</strong>: Access to personal information will be restricted to authorized personnel only, and strict access controls will be enforced.</li>
    <li><strong>Data Backup</strong>: Regular backups of personal information will be conducted to prevent data loss.</li>
    <li><strong>Data Anonymization</strong>: Where applicable, personal information will be anonymized to further protect your privacy.</li>
    <li><strong>Data Retention</strong>: Personal information will be retained only for as long as necessary to fulfill the purposes outlined in this Agreement, after which it will be securely deleted or anonymized.</li>
</ul>
<p><strong>Use of Personal Information</strong></p>
<p>You agree that the Company may use your personal information for recruitment purposes, including but not limited to evaluating your application and conducting assessments.</p>
<p><strong>Online Assessments</strong></p>
<p>You acknowledge that you will be subject to online assessments as part of the recruitment process.</p>
<p><strong>Interview Selection</strong></p>
<p>You understand that only the highest-scoring applicants from the online assessments will be contacted for interviews.</p>
<p><strong>No Guarantee of Placement</strong></p>
<p>You accept that by submitting your information, you are not guaranteed a place on the learnership program.</p>
<p><strong>Accuracy of Information</strong></p>
<p>You confirm that all the information you have submitted is true and correct to the best of your knowledge.</p>
<p><strong>Document Submission</strong></p>
<p>As part of your application, you agree to submit the following documents:</p>
<ol>
    <li>A scanned copy of your identity document;</li>
    <li>A scanned copy of your original Matric certificate;</li>
    <li>Proof of residence;</li>
    <li>Your Curriculum Vitae (CV).</li>
</ol>
<p><strong>Acceptance of this Agreement and Disclaimer</strong></p>
<p>You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By submitting your application, you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorised to use our Services.</p>
<FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                        name="acceptTerms"
                                    />
                                }
                                label="I have read and agree to the terms and conditions."
                            />
                      </div>
                    </div>
                  </div>
                )}
                {/* Basic Info Step */}
                {activeStep == 1 && (
                  <div className={"row pb-2"}>
                    <div className="col-md-9 my-2 ">
                      {/* Form name */}
                      <h4 className="">
                        Tell us about yourself
                        <span className="">
                          &nbsp;&nbsp;
                          {isEditMode && (
                            <div className="d-inline-block">
                              {recordToUpdate.status == 3 && (
                                <FontAwesomeIcon
                                  className="text-danger"
                                  icon="times"
                                />
                              )}

                              {recordToUpdate.status == 2 && (
                                <FontAwesomeIcon
                                  className="text-success"
                                  icon="check"
                                />
                              )}

                              {recordToUpdate.status == 1 && (
                                <FontAwesomeIcon
                                  className="text-warning"
                                  icon="exclamation-triangle"
                                />
                              )}
                            </div>
                          )}
                        </span>
                      </h4>
                    </div>

                    <div className="col-md-3">
                      {/* Field isBlocked */}
                      {isEditMode && (
                        <div className="">
                          {recordToUpdate.status == 1 && (
                            <ToggleButtonGroup
                              color="primary"
                              value={status}
                              exclusive
                              onChange={handleChange}
                            >
                              <ToggleButton value="2">Approve</ToggleButton>
                              <ToggleButton value="3">Reject</ToggleButton>
                            </ToggleButtonGroup>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Field titleId */}
                    <div className="col-md-6 mt-4">
                      {allTitle.length == 0 && <Skeleton />}

                      {allTitle.length > 0 && (
                        <FormControl fullWidth={true}>
                          <InputLabel id="titleIdLabel">Title</InputLabel>
                          {allTitle.length > 0 && (
                            <Select
                              labelId="titleIdLabel"
                              id="titleIdSelect"
                              {...register("Title", { required: true })}
                              error={!!errors.Title}
                              value={watch("Title")}
                              label="titleId"
                            >
                              {allTitle.map((el: any) => (
                                <MenuItem value={el.titleID} key={el.titleID}>
                                  {" "}
                                  {el.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      )}
                    </div>

                    {/* Field name */}
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="nameInput"
                          {...register("Name", { required: true })}
                          label={t("application.name")}
                          type={"text"}
                          error={!!errors.Name}
                          variant="outlined"
                          className="full-width"
                        />
                      </FormControl>
                    </div>

                    {/* Field surnamename */}
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="nameInput"
                          {...register("Surname", { required: true })}
                          label={t("application.surname")}
                          type={"text"}
                          error={!!errors.Surname}
                          variant="outlined"
                          className="full-width"
                        />
                      </FormControl>
                    </div>

                    {/* Field contact */}
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <InputMask
                          mask="999 999 9999"
                          disabled={false}
                          maskChar=" "
                          {...register("Mobile_number", { required: true })}
                          error={!!errors.Mobile_number}
                          onChange={(e) => {
                            setValue("Mobile_number", e.target.value);
                          }}
                        >
                          {() => (
                            <TextField
                              id="contactInput"
                              label={t("application.contact")}
                              variant="outlined"
                              className="full-width"
                            />
                          )}
                        </InputMask>
                      </FormControl>
                    </div>

                    {/* Field email */}
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <TextField
                          id="emailInput"
                          {...register("Email_Address", { required: true })}
                          label={t("application.email")}
                          type={"email"}
                          error={!!errors.Email_Address}
                          variant="outlined"
                          className="full-width"
                        />
                      </FormControl>
                    </div>

                    {/* Field nationalid */}
                    {/*<div className="col-md-6 mt-4">*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <TextField id="nameInput"*/}
                    {/*                   {...register("nationalId", {required: true})}*/}
                    {/*                   label={t('application.nationalId')}*/}
                    {/*                   type={"text"}*/}
                    {/*                   error={!!errors.name}*/}
                    {/*                   variant="outlined"*/}
                    {/*                   className="full-width"/>*/}
                    {/*    </FormControl>*/}
                    {/*</div>*/}

                    {/* Tax ID Toggle */}
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <FormLabel component="legend">
                          Are you Registered with SARS?
                        </FormLabel>
                        <ToggleButtonGroup
                          color="primary"
                          value={hasTaxID ? "yes" : "no"}
                          exclusive
                          onChange={handleTaxIDToggle}
                          fullWidth // Make the toggle group full width
                          className="full-width" // Add a custom class
                        >
                          <ToggleButton value="yes" className="full-width">
                            Yes
                          </ToggleButton>
                          <ToggleButton value="no" className="full-width">
                            No
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </div>

                    {/* Conditional Tax ID Field */}
                    {hasTaxID && (
                        <div className="col-md-6 mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="taxIdInput"
                            {...register("Tax_Number", { required: true })}
                            label={t("application.taxId")}
                            type={"number"}
                            error={!!errors.Tax_Number}
                            variant="outlined"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className="full-width"
                          />
                        </FormControl>
                      </div>
                    )}

                    {/* Field educationId */}
                    <div className="col-md-6 mt-4">
                      {allEducation.length == 0 && <Skeleton />}

                      {allEducation.length > 0 && (
                        <FormControl fullWidth={true}>
                          <InputLabel id="educationIdLabel">
                            Education
                          </InputLabel>
                          {allEducation.length > 0 && (
                            <Select
                              labelId="educationIdLabel"
                              id="educationIdSelect"
                              {...register("Highest_Qualification", {
                                required: true,
                              })}
                              error={!!errors.Highest_Qualification}
                              value={watch("Highest_Qualification")}
                              label="educationId"
                            >
                              {allEducation.map((el: any) => (
                                <MenuItem value={el.level} key={el.id}>
                                  {" "}
                                  {el.level}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      )}
                    </div>

                    {/* Field ID Number */}
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <InputMask
                          mask="9999999999999"
                          disabled={false}
                          maskChar=" "
                          {...register("ID_Number", { required: true })}
                          error={!!errors.Mobile_number}
                          onChange={(e) => {
                            setValue("ID_Number", e.target.value);
                          }}
                        >
                          {() => (
                            <TextField
                              id="idInput"
                              label={t("application.idNumber")}
                              variant="outlined"
                              className="full-width"
                            />
                          )}
                        </InputMask>
                      </FormControl>
                    </div>
                    
                  </div>
                )}

                {/* Bank Details Step */}
                {activeStep == 2 && (
                  <div className={"row pb-2"}>
                    {/* Field bankDetailsId */}
                    <div className="col-md-12 my-2">
                      {/* Form name */}
                      <h4 className="">Bank Details</h4>
                    </div>
                    <div className="col-md-6 mt-4">
                      <FormControl fullWidth>
                        <FormLabel component="legend">
                          Has Bank Account?
                        </FormLabel>
                        <ToggleButtonGroup
                          color="primary"
                          value={hasBankAccount ? "yes" : "no"}
                          exclusive
                          onChange={handleBankAccountToggle}
                          fullWidth
                          className="full-width"
                        >
                          <ToggleButton value="yes" className="full-width">
                            Yes
                          </ToggleButton>
                          <ToggleButton value="no" className="full-width">
                            No
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </div>
                    {/* Field bank */}
                    {hasBankAccount && (
                      <>
                        <div className="col-md-6 mt-4">
                          <FormControl fullWidth>
                            <Autocomplete
                              id="combo-box-demo"
                              options={allBanks}
                              autoHighlight
                              {...register("Bank", { required: true })}
                              error={!!errors.Bank}
                              onChange={(
                                event: any,
                                _newValue: string | null
                              ) => {
                                setValue("Bank", _newValue.id);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("bankdetail.bank")}
                                />
                              )}
                            />
                          </FormControl>
                        </div>

                        {/* Field accountType */}
                        <div className="col-md-6 mt-4">
                          <FormControl fullWidth={true}>
                            <InputLabel id="accountTypeInput">
                              {"Bank Account Type"}
                            </InputLabel>
                            {allBankAccountTypes.length > 0 && (
                              <Select
                                labelId="accountTypeInput"
                                id="accountTypeInput"
                                {...register("Bank_Account_Type", {
                                  required: true,
                                })}
                                error={!!errors.Bank_Account_Type}
                                value={watch("Bank_Account_Type")}
                                label={"Bank Account Type"}
                              >
                                {allBankAccountTypes.map((el: any) => (
                                  <MenuItem
                                    value={el.accountID}
                                    key={el.accountID}
                                  >
                                    {" "}
                                    {el.bank}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </FormControl>
                        </div>

                        {/*/!* Field address *!/*/}
                        {/*<div className="col-md-6 mt-4">*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <TextField id="addressInput"*/}
                        {/*                   {...register("Bank_Branch_Code", {required: true})}*/}
                        {/*                   label={'Branch Code'}*/}
                        {/*                   type={"text"}*/}
                        {/*                   error={!!errors.Bank_Branch_Code}*/}
                        {/*                   variant="outlined"*/}
                        {/*                   className="full-width"/>*/}
                        {/*    </FormControl>*/}
                        {/*</div>*/}

                        {/* Field payee */}
                        <div className="col-md-6 mt-4">
                          <FormControl fullWidth>
                            <TextField
                              id="payeeInput"
                              {...register("Bank_Account_Holder", {
                                required: true,
                              })}
                              label={"Bank Account Number"}
                              type={"number"}
                              error={!!errors.Bank_Account_Holder}
                              variant="outlined"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.input + " full-width"}
                            />
                          </FormControl>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* Script Reading Step */}
                {activeStep == 3 && (
                  <div className={"row pb-2"}>
                    <div className="col-md-12 my-2">
                      {/* Form name */}
                      <h4 className="">Let's simulate reading a script</h4>
                    </div>

                    <div className="col-md-12">
                      <Alert severity="warning">
                        Once you have allowed access to your microphone click
                        the 'record' button and read the script out as best you
                        can. Once you're done, dick the 'Stop' button. (You will
                        need to plug in headphones with a mic for this process)
                      </Alert>

                      <div
                        className="my-5"
                        onClick={() => {
                          setIsReadingScriptRecorded(true);
                          validateNextButton();
                        }}
                      >
                        <AudioRecorder ref={scriptSimulationAudioBlobRef} />
                      </div>

                      <div>
                        <h5 className={"mb-3"}>Script</h5>
                        <p>
                          "Good day Mr/Mrs Jones. You are speaking with Peter
                          and I am calling you about your interest in a
                          cell-phone promotion from mycelldeals.co.za, OR a loan
                          from mobi-loans.co.za, Or a credit card from
                          bluecreditdeals.co.za How are you today?
                        </p>
                        <p>
                          Now Mr/Mrs Jones we are proud to say that each day we
                          help thousands of South Africans with either loans,
                          credit cards or cell-phone contracts even if they have
                          been declined in the past with other companies...
                        </p>
                        <p>
                          But before I carry on, I must mentio that all calls
                          are being recorded for quality and security purposes
                          and can be made available to you upon request, OK?
                        </p>
                        <p>
                          I have all your details now, your information will be
                          sent through to the Service Providing companies for
                          assessment in order to find you the best deal"
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Sales Simulation Step */}
                {activeStep == 4 && (
                  <div className={"row pb-2"}>
                    <div className="col-md-12 my-2">
                      {/* Form name */}
                      <h4 className="">
                        You will now go through a simulate sales test
                      </h4>
                    </div>

                    <div className="col-md-12">
                      <Alert severity="warning">
                        Please press play on the provided recording, listen
                        carefully and fill out the necessary fields with the
                        correct client information.
                      </Alert>

                      <div className="my-5">
                        <SalesSimulation />
                      </div>

                      <div>
                        <h5 className={"mb-3"}>Questions</h5>

                        {/* Questions */}
                        <div className="row">
                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <InputLabel id="titleIdLabel">Title</InputLabel>
                              {allTitle.length > 0 && (
                                <Select
                                  labelId="salesTitleIdLabel"
                                  id="salesTitleIdSelect"
                                  {...register("sales.SStitle", {
                                    required: true,
                                  })}
                                  // error={!!errors.sales.SStitle}
                                  value={watch("sales.SStitle")}
                                  label="salesTitleId"
                                >
                                  {allTitle.map((el: any) => (
                                    <MenuItem
                                      value={el.titleID}
                                      key={el.titleID}
                                    >
                                      {" "}
                                      {el.title}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesFirstName"
                                {...register("sales.SSname", {
                                  required: true,
                                })}
                                label={"First Name"}
                                type={"text"}
                                // error={!!errors.sales.SSname}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesSurName"
                                {...register("sales.SSSurname", {
                                  required: true,
                                })}
                                label={"Surname"}
                                type={"text"}
                                // error={!!errors.sales.SSSurname}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesIdNumber"
                                {...register("sales.SSIDNumber", {
                                  required: true,
                                })}
                                label={"ID Number"}
                                type={"number"}
                                // error={!!errors.sales.SSIDNumber}
                                variant="outlined"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                className={classes.input + " full-width"}
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesGrossIncome"
                                {...register("sales.SSgrossincome", {
                                  required: true,
                                })}
                                label={"Gross Income"}
                                type={"text"}
                                // error={!!errors.sales.SSgrossincome}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesEmail"
                                {...register("sales.SSemailaddress", {
                                  required: true,
                                })}
                                label={"Email Address"}
                                type={"email"}
                                // error={!!errors.sales.SSemailaddress}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesAddress"
                                {...register("sales.SSaddress", {
                                  required: true,
                                })}
                                label={"Physical Address"}
                                type={"text"}
                                // error={!!errors.sales.SSaddress}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesCity"
                                {...register("sales.SScity", {
                                  required: true,
                                })}
                                label={"City"}
                                type={"text"}
                                // error={!!errors.sales.SScity}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesSuburb"
                                {...register("sales.SSsuburb", {
                                  required: true,
                                })}
                                label={"Suburb"}
                                type={"text"}
                                // error={!!errors.sales.SSsuburb}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>

                          {/* Field Question */}
                          <div className="col-md-6 mt-4">
                            <FormControl fullWidth>
                              <TextField
                                id="salesProvince"
                                {...register("sales.SSprovince", {
                                  required: true,
                                })}
                                label={"Province"}
                                type={"text"}
                                // error={!!errors.sales.SSprovince}
                                variant="outlined"
                                className="full-width"
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Interview Step */}
                {activeStep == 5 && (
                  <div className={"row pb-2"}>
                    <div className="col-md-12 my-2">
                      {/* Form name */}
                      <h4 className="">Interview</h4>
                    </div>

                    <div className="col-md-12">
                      {/* Questions */}
                      <div>
                        <div className="row">
                          <div>
                            {questions.map((q: any, index: number) => {
                              if (
                                getQuestionTypeName(q.questionType) == "Text"
                              ) {
                                return (
                                  // text type question
                                  <div className="row">
                                    {/*Field Question*/}
                                    <div
                                      className="col-md-6 mt-4"
                                      style={{ whiteSpace: "pre-line" }}
                                    >
                                      <p>{q.question}</p>
                                    </div>

                                    {/* Field Answer */}
                                    <div className="col-md-6 mt-4">
                                      <FormControl fullWidth>
                                        <TextField
                                          id="question1"
                                          {...register(
                                            "interview.interview-" +
                                              q.questionID,
                                            { required: true }
                                          )}
                                          label={"Answer"}
                                          type={"text"}
                                          multiline
                                          maxRows={4}
                                          minRows={2}
                                          error={!!errors.question1}
                                          variant="outlined"
                                          className="full-width"
                                        />
                                      </FormControl>
                                    </div>
                                  </div>
                                );
                              } else if (
                                getQuestionTypeName(q.questionType) ==
                                "Voice Recording"
                              ) {
                                return (
                                  // number type question
                                  <div className="row">
                                    {/*Field Question*/}
                                    <div
                                      className="col-md-6 mt-4"
                                      style={{ whiteSpace: "pre-line" }}
                                    >
                                      <p>{q.question}</p>
                                    </div>

                                    {/* Field Answer */}
                                    <div
                                      className={
                                        "col-md-6 mt-4 " +
                                        (isRecording && indexRecording != index
                                          ? classes.noClick
                                          : "")
                                      }
                                      onClick={() => {
                                        recordAnswerCount(q.questionID);
                                        setIsRecording(!isRecording);
                                        setIndexRecording(index);
                                      }}
                                    >
                                      <AudioRecorder
                                        ref={interviewAudioRefs[index]}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row pt-4 pb-3">
                  <div className="col-2 me-auto pl-0">
                    <img
                      className="img-fluid py-2 pl-0 me-auto"
                      src={require("../../../../../assets/logo/logo.png")}
                    />
                  </div>
                  <div className="col-9">
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {/*<Button*/}
                      {/*    color="inherit"*/}
                      {/*    disabled={activeStep === 0}*/}
                      {/*    onClick={handleBack}*/}
                      {/*    sx={{mr: 1}}*/}
                      {/*>*/}
                      {/*    Back*/}
                      {/*</Button>*/}
                      <Box sx={{ flex: "1 1 auto" }} />

                      {activeStep !== steps.length && (
                        <Button
                          onClick={handleNext}
                          disabled={validateNextButton() != true}
                        >
                          {activeStep === 0 ? "Accept" : activeStep === 7 ? "Done" : "Next"}
                        </Button>
                      )}

                      {isStepOptional(activeStep) && (
                        <Button
                          color="inherit"
                          onClick={handleSkip}
                          sx={{ mr: 1 }}
                        >
                          Skip
                        </Button>
                      )}

                      {/*{activeStep === steps.length - 1 &&*/}
                      {/*    <LoadingButton loading={isLoading}*/}
                      {/*                   type="submit"*/}
                      {/*                   variant="contained"*/}
                      {/*                   className="bg-custom-gradient">*/}
                      {/*        {isEditMode ? t('common.edit') : 'Submit'}*/}
                      {/*    </LoadingButton>*/}
                      {/*}*/}
                    </Box>
                  </div>
                </div>
              </form>
            </React.Fragment>
          )}
        </Card>
      </Box>

      <div style={{ position: "position: relative" }}>
        {/* Alerts */}

        <AlertM ref={notifications} />

        {!isValid && (
          <div className="alert alert-danger" role="alert">
            {validError}
          </div>
        )}
      </div>
    </div>
  );
}

export default ApplicationForm;
