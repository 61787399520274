import React, {useContext, useEffect} from 'react';
import {Button, Card, CardContent, TextField, Typography, Box, LinearProgress} from "@mui/material";
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {useStyles} from './Styles';
import {RootStateOrAny, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Webcam from "react-webcam";
import {SocketContext} from "../../../../config/socket";
import config from '../../../../config/config';


const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")


// interfaces
interface SocketIoResponse {
    employeeId: any;
    cameraId: string;
    datetime: string;
    frame: any;
}

// data vars
let socketIOStreamingId: any = 0;

function LoginFace() {
    const classes = useStyles();
    const navigate = useNavigate();

    // refs
    const webcamRef = React.useRef(null);
    const imgRef = React.useRef(null);

    const socket = useContext(SocketContext);
    // const [socketIOStreamingId, setSocketIOStreamingId] = React.useState(0);
    const [deviceId, setDeviceId] = React.useState('TAB-001');
    const [results, setResults] = React.useState<Array<SocketIoResponse>>([]);
    const [{
        srcBlob,
        srcDataUri
    }, setSrc] = React.useState({
        srcBlob: null,
        srcDataUri: null
    });


    // ui controls
    const [showWebcam, setShowWebcam] = React.useState(false);


    const authentication = useSelector(
        (state: RootStateOrAny) => state.authentication,
    );

    const sleep = (milliseconds: number | undefined) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    // socket io listener
    useEffect(() => {
        // @ts-ignore
        socket.on("classifier_result", async (_data: SocketIoResponse) => {
            if (_data.employeeId != 'unknown') {
                // display image
                // plotFrame(_data.frame);

                if (results.some((_item: SocketIoResponse) => _item?.employeeId == _data?.employeeId && _item?.datetime == _data?.datetime)) {
                    // if username already in list
                } else {
                    setResults(results => [...results, _data]);
                    if (results.length >= 5) {
                        console.log(results.shift());
                    }

                    console.log(_data);
                    console.log(results);

                    console.log("interval id in hook method: " + socketIOStreamingId);

                    stopVideoStreamingToServer()

                    navigate("/dashboard");
                }
            }
        });
        // unsubscribe from event for preventing memory leaks
        return () => {
            socket.off('WELCOME_FROM_SERVER', () => {
                console.log('connection closed');
            });
        };
    }, []);


    // video streaming methods
    function startVideoStreamingToServer(_cameraId: any) {
        // Classification mode
        console.log('started recognition');

        let intervalId = setInterval(() => {
            // @ts-ignore
            socket.emit("classification_frame", {
                // @ts-ignore
                frame: webcamRef.current.getScreenshot(),
                cameraId: _cameraId
            });
        }, (config.FPS * 1000));

        socketIOStreamingId = intervalId;
        console.log("interval id in start method: " + socketIOStreamingId);
    }

    function stopVideoStreamingToServer() {
        console.log('stopped recognition');
        console.log("interval id in stop method: " + socketIOStreamingId);
        clearInterval(socketIOStreamingId);
        // @ts-ignore
        socket.off();
        setDeviceId('');
        setShowWebcam(false);
    }


    // on change/ on click methods
    const onClickLoginButton = () => {
        if (!showWebcam) {
            startVideoStreamingToServer(deviceId);
        } else {
            stopVideoStreamingToServer()
        }
        setShowWebcam(!showWebcam);
    }


    return (
        <section className="full-width h-100">
            <div className="row h-100">

                {/* Content Div */}
                <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-center h-100 ">
                        <div className="px-md-5 px-4 full-width">

                            {!showWebcam &&
                                <div className="">
                                    <div className="row px-2">
                                        <h1 className="mb-3 fw-bolder">
                                            Login
                                        </h1>
                                        <p>Login using your Face ID</p>

                                        <img className="img-fluid text-center mx-auto my-5" style={{maxWidth: '256px'}}
                                             src={require('../../../../assets/illustration/face-id.png')}/>

                                        <Button variant="contained"
                                                className={classes.bgGradient + ' my-2 py-3'}
                                                onClick={() => {
                                                    onClickLoginButton()
                                                }}>
                                            Login
                                        </Button>

                                        <p className="my-2">
                                            Don't have an account?&nbsp;
                                            <span className="fw-bolder text-theme-dark cursor-pointer"
                                                  onClick={() => navigate('/register')}>
                                            Register
                                        </span>
                                        </p>
                                    </div>
                                </div>
                            }

                            {showWebcam &&
                                <div>
                                    {/*<h3 className="text-center mb-3">*/}
                                    {/*    */}
                                    {/*</h3>*/}


                                    <div className="row px-5">
                                        <Webcam
                                            ref={webcamRef}
                                            className="img-fluid"
                                            mirrored
                                            screenshotFormat="image/jpeg"
                                        />
                                        <img className={classes.inputImage} alt="input" ref={imgRef}/>

                                        <div className="mt-1">
                                            <Button variant="contained"
                                                    className={classes.bgGradient + ' my-2 py-3 full-width'}
                                                    onClick={() => {
                                                        onClickLoginButton()
                                                    }}>
                                                Stop
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>


                {/* Hero Div */}
                <div className="col-md-6">
                    <div className="">
                        <Card sx={{m: 3, minHeight: '95vh', boxShadow: 10}} className={classes.bgGradient}>
                            <div className="d-flex align-items-center justify-content-center"
                                 style={{minHeight: '95vh'}}>
                                <img className="img-fluid px-3"
                                     src={require('../../../../assets/logo/logo-white.png')}/>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default LoginFace;
