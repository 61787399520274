import React, {useEffect} from 'react';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {expand} from "./Actions";
import '../../../index.scss';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Header() {
    // Set Theme
    const theme = useTheme();

    // Redux
    const header = useSelector(
        (state: RootStateOrAny) => state.header,
    );

    const sider = useSelector(
        (state: RootStateOrAny) => state.sider,
    );

    // Dispatcher
    const dispatch = useDispatch()

    // UI vars
    const [open, setOpen] = React.useState(header.state.isExpanded);

    // Hooks
    useEffect(() => {
    }, [header]);

    useEffect(() => {
        setOpen(sider.state.isExpanded)
    }, [sider]);


    // UI toggles
    const handleDrawerOpen = () => {
        dispatch(expand())
        setOpen(true);
    };

    return (
        <AppBar position="fixed" open={open} className="bg-custom-gradient">
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && {display: 'none'}),
                    }}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    {process.env.REACT_APP_PROJECT_NAME}
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
