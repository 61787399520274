// @ts-nocheck
import React, {useRef} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {patch, post} from "../../../../../Services/GenericApiService";
import {useTranslation} from "react-i18next";
import ApplicationDocumentsForm from "../ApplicationDocumentsForm/ApplicationDocumentsForm";
import {log} from "../../../../../Services/LoggerService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {Button, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Card} from "@mui/material";
import AlertM from '../../../../Helpers/AlertM/AlertM';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

// @ts-ignore
function ApplicationDocumentsUpdate({refreshDataEvent, recordToUpdate, resetEditState}) {
    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'application';

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);


    // delay before closing update screen
    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        setLoading(true); // show loader
        patch(moduleMain, _formData.id, _formData)
            .then(async (_res) => {
                setLoading(false); // hide loader
                refreshDataEvent(); // refresh data
                handleClose();
                // @ts-ignore
                notifications.current.successAlert(t('common.editSuccess'), '');
                // @ts-ignore
                await sleep(process.env.REACT_APP_UPDATE_NOTIFICATION_TIMEOUT_MS || 1000);
                resetEdit();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
        resetEdit();
    }


    // reset isEdit to false in main component
    const resetEdit = () => {
        resetEditState(true);
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{minWidth: 475}}>
                            <div className="mt-4 mb-5 px-md-3 px-2">
                                <h2 className="my-3">
                                    Edit Documents
                                </h2>
                                <nav aria-label="main mailbox folders">
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemText primary="Document1.docx" />
                                                <ListItemIcon>
                                                    <FontAwesomeIcon className="delete-btn" icon='trash' onClick={handleOpen} />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemText primary="Document2.docx" />
                                                <ListItemIcon>
                                                    <FontAwesomeIcon className="delete-btn" icon='trash' onClick={handleOpen} />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </nav>
                            </div>
                        </Card>
                    </Box>
                </Fade>
            </Modal>


           {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
}

export default ApplicationDocumentsUpdate;
