import {SWITCH_LANGUAGE} from "./Types";

const initialState = {
    global: {
        language: 'en'
    }
}

const GlobalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SWITCH_LANGUAGE: return {
            ...state,
            global: {
                language: action.language
            }
        }
        default: return state
    }
}

export default GlobalReducer
