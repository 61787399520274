import React from 'react';
import {Button, Card, CardContent, TextField, Typography} from "@mui/material";
import {Typewriter, useTypewriter, Cursor} from 'react-simple-typewriter'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useStyles} from './Styles';
import ApplicationCreate from '../../Dashboard/Application/ApplicationCreate/ApplicationCreate';
import { useParams, useLocation } from 'react-router-dom';

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

function OnboardCreate() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const deviceId: any = queryParams.get('deviceId');

    console.log('devicde Id: ', deviceId)

    // css for module
    const classes = useStyles();

    return (
        <div>
            <div className="row">
                <div className="col-md-2 col-5">
                    <img className="img-fluid py-2 px-5"
                         src={require('../../../../assets/logo/logo.png')}/>
                </div>
            </div>


            <div className={classes.left + " row d-flex align-items-center"}>

                {/* left content div*/}
                <div className="col-md-5">
                    <div className="container ps-5">
                        <h1 className="fw-bolder mb-4"> L
                            <Typewriter
                                words={["et's get you on-boarded"]}
                                loop={1}
                                cursor={false}
                                cursorStyle='_'
                                typeSpeed={70}
                                deleteSpeed={50}
                                delaySpeed={1000}

                            />
                        </h1>

                        <p className="mb-5">
                            Click on the button below to fill in your basic information. After submission, you should
                            receive an email with a link to register your Face ID.
                        </p>

                        <ApplicationCreate deviceId={deviceId}
                        refreshDataEvent={() => {
                        }}/>


                    </div>
                </div>

                {/*  right content div  */}
                <div className="col-md-7 justify-content-center text-center">
                    <img className="img-fluid"
                         src={require('../../../../assets/illustration/form.png')} width="70%"/>
                </div>
            </div>

        </div>
    );
}


export default OnboardCreate;
