import React, {useRef} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {post, postTCH} from "../../../../../Services/GenericApiService";
import {useTranslation} from "react-i18next";
import ApplicationForm from "../ApplicationForm/ApplicationForm";
import {log} from "../../../../../Services/LoggerService";
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import {Button, Card, CardContent, Typography, Divider } from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ApplicationFormSimulations from '../ApplicationFormSimulations/ApplicationFormSimulations';

// @ts-ignore
function ApplicationCreate({ refreshDataEvent, showSimulationsForm = false, deviceId = '' }) {
    // css for module
    const classes = useStyles();

    // Routing data
    const location = useLocation();

    // module(s) for api
    const moduleMain = 'application';
    const moduleEmail = 'integration/';
    const moduleBank = 'bankdetail';
    const moduleAddUserTCH = 'AddUser';

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(false);

    // handle add record event
    const handleAddEvent = (_formData: any) => {
        setLoading(true); // show loader
        // add module in form data for tch
        _formData.method = moduleAddUserTCH;

        // create bank details
        // post(moduleBank, _formData)
        postTCH(_formData)
            .then(_res => {
                post(moduleEmail + 'sendRegistrationMail', {
                    id: _res.userID,
                    email: _formData.Email_Address
                })
                    .then(_resApplicant => {
                        setLoading(false); // hide loader
                        refreshDataEvent(); // refresh data
                        handleClose();
                        // @ts-ignore
                        notifications.current.successAlert(t('common.createSuccess'), '');
                    })
                    .catch(_err => {
                        log(_err)
                        setLoading(false); // hide loader
                        // @ts-ignore
                        notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                    })
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    const redirectToWebSystemURL = () => {
        window.location.href = `https://italkuat.tch.co.za/loginform.asp?deviceId=${deviceId}`
    }


    return (
        <div>
            <Button
                variant="contained"
                onClick={handleOpen}
                className={'bg-custom-gradient my-2 py-3'}
                fullWidth>
                    <FontAwesomeIcon width="2em" size="lg" icon='plus' />
                { location.pathname.includes('onboard') ? 'register' : 'Register New User'}
            </Button>

            {/* Divider */}
            <Divider className="my-2"><span className="text-muted">OR</span></Divider>

            <Button
                variant="contained"
                onClick={redirectToWebSystemURL}
                className={'bg-custom-gradient my-2 py-3'}
                fullWidth>
                    <FontAwesomeIcon width="2em" size="lg" icon='address-book' />
                { location.pathname.includes('onboard') ? 'register' : 'Existing User'}
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={classes.modalScroll}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        {!showSimulationsForm &&
                            <ApplicationForm handleAddEvent={handleAddEvent}
                                             isEditMode={false}
                                             deviceId={deviceId}
                                             handleEditEvent={() => {
                                             }}
                                             handleCancelEvent={handleCancelEvent}
                                             recordToUpdate={null}
                                             isLoading={loading}/>
                        }

                        {showSimulationsForm &&
                            <ApplicationFormSimulations />
                        }
                    </Box>
                </Fade>
            </Modal>


            {/* Alerts */}
            <AlertM ref={notifications}/>

        </div>
    );
}

export default ApplicationCreate;
