// @ts-nocheck

import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {Button, Card, CardContent, IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import {log} from "../../../../../Services/LoggerService";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function ApplicationView({data}) {
    // css for module
    const classes = useStyles();

    // translation
    const {t} = useTranslation();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    // hooks
    useEffect(() => {

    }, []);


    return (
        <div>
            <Box sx={{display: 'flex'}}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye'/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{minWidth: '75vw'}}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}
                                        <span className="">
                                            &nbsp;&nbsp;
                                            {data.status == 3 &&
                                                <FontAwesomeIcon className="text-danger" icon='times'/>
                                            }

                                            {data.status == 2 &&
                                                <FontAwesomeIcon className="text-success" icon='check'/>
                                            }

                                            {data.status == 1 &&
                                                <FontAwesomeIcon className="text-warning" icon='exclamation-triangle'/>
                                            }
                                        </span>
                                    </h1>
                                    <div className="row full-width">

                                        <div className="col-md-6">
                                            <DataDisplayM label={'Is Blocked'} data={data.isBlocked ? 'Yes' : 'No'}/>
                                        </div>

                                        <div className="col-md-6">
                                            <DataDisplayM label={'Is Face Registered'}
                                                          data={data.isFaceRegistered ? 'Yes' : 'No'}/>
                                        </div>

                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.empId')} data={"emp-00" + data.id}/>
                                        </div>

                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.titleId')}
                                                          data={data.applicantTitle?.name}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.name')} data={data.name}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.surname')} data={data.surname}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.nationalId')} data={data.nationalId}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.contact')} data={data.contact}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.email')} data={data.email}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.bankDetailsId')}
                                                          data={data.applicantBank?.accountNumber}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.taxId')} data={data.taxId}/>
                                        </div>


                                        <div className="col-md-6">
                                            <DataDisplayM label={t('application.educationId')}
                                                          data={data.applicantEducation?.level}/>
                                        </div>


                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                            onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default ApplicationView;
