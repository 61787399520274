// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
            '-webkit-appearance': 'none',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    noClick: {
        pointerEvents: 'none'
    }
});

export { useStyles }
