export const isDate = (_date: string) => {
    // @ts-ignore
    return (new Date(_date) !== "Invalid Date") && !isNaN(new Date(_date));
}

export const toTitleCase = (_str: string) => {
    return _str.replace(
        /\w\S*/g,
        function (_txt) {
            return _txt.charAt(0).toUpperCase() + _txt.substr(1).toLowerCase();
        }
    );
}

export const formatData = (_paramsArray: any) => {
    let formattedArray: any = [];
    _paramsArray.forEach((_item: any) => {

        // check if undefined
        if (_item == null) {
            formattedArray.push('N/A');
        }

        // check if boolean
        else if (_item == true || _item == false) {
            formattedArray.push(_item == true ? 'Yes' : 'No');
        }

        // // check if date
        // if (isDate(_item)) {
        //     formattedArray.push(new Date(_item).toLocaleDateString("en-US"));
        // }

        // check if normal string
        else {
            formattedArray.push(toTitleCase(_item));
        }
    })
    return formattedArray;
}
