import React, { useState, useRef } from 'react';
import { Button } from "@mui/material"; // Ensure all imports are at the top
const audioFile = require('../../../../assets/audio/salesscript.mp3'); // Keep require after import statements

const SalesSimulation = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [playCount, setPlayCount] = useState(0);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const handlePlay = () => {
        if (playCount < 2 && audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleEnded = () => {
        setIsPlaying(false);
        setPlayCount(playCount + 1);
    };

    return (
        <div className={'container'}>
            <div className="row px-3 mb-3">
                <div className="col-12 text-center">
                    {!isPlaying && playCount < 2 && (
                        <Button variant="contained" onClick={handlePlay} fullWidth size={'large'}>
                            Play
                        </Button>
                    )}
                    {isPlaying && (
                        <img src={'https://rtionline.gov.in/sound_200.gif'} className={'img-fluid'} width={'50px'} />
                    )}
                    {playCount >= 2 && !isPlaying && (
                        <div style={{ color: 'red' }}>
                            You cannot play the audio more than two times.
                        </div>
                    )}
                    <audio ref={audioRef} onEnded={handleEnded} className="d-none">
                        <source type="audio/mpeg" src={audioFile} />
                    </audio>
                </div>
            </div>
        </div>
    );
};

export default SalesSimulation;
