import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {post, getAll} from "../../../../../Services/GenericApiService";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import {Button, FormControl, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";


// @ts-ignore
function BankdetailForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {
    // css for module
    const classes = useStyles();

    // router object
    const navigate = useNavigate();

    // module(s) for api
    const moduleMain = 'bankdetail';



    // translation
    const {t} = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    const onCancel = () => {
        handleCancelEvent();
    }

    // ui handlers

    // fetch dependent data



    const fetch = () => {

    }



    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);
        }
    }, []);


    return (
        // <Box sx={{display: 'flex'}}>
        //     <Card sx={{minWidth: 275}}>
          <div>
              {/* Form start */}
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                      <div className="py-2">
                          <div className="row">

                              <div className="col-md-12 my-2">
                                  {/* Form name */}
                                  <h4 className="pb-1">
                                      Bank Details
                                  </h4>

                              </div>



                              {/* Field accountNumber */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="accountNumberInput"
                                                 {...register("accountNumber", {required: true})}
                                                 label={t('bankdetail.accountNumber')}
                                                 type={"text"}
                                                 error={!!errors.accountNumber}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>

                              {/* Field accountType */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="accountTypeInput"
                                                 {...register("accountType", {required: true})}
                                                 label={t('bankdetail.accountType')}
                                                 type={"text"}
                                                 error={!!errors.accountType}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>

                              {/* Field bank */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="bankInput"
                                                 {...register("bank", {required: true})}
                                                 label={t('bankdetail.bank')}
                                                 type={"text"}
                                                 error={!!errors.bank}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>

                              {/* Field address */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="addressInput"
                                                 {...register("address", {required: true})}
                                                 label={t('bankdetail.address')}
                                                 type={"text"}
                                                 error={!!errors.address}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>

                              {/* Field iban */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="ibanInput"
                                                 {...register("iban", {required: true})}
                                                 label={t('bankdetail.iban')}
                                                 type={"text"}
                                                 error={!!errors.iban}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>

                              {/* Field bicswift */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="bicswiftInput"
                                                 {...register("bicswift", {required: true})}
                                                 label={t('bankdetail.bicswift')}
                                                 type={"text"}
                                                 error={!!errors.bicswift}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>

                              {/* Field payee */}
                              <div className="col-md-6 mt-4">
                                  <FormControl fullWidth>
                                      <TextField id="payeeInput"
                                                 {...register("payee", {required: true})}
                                                 label={t('bankdetail.payee')}
                                                 type={"text"}
                                                 error={!!errors.payee}
                                                 variant="outlined"
                                                 className="full-width"/>
                                  </FormControl>
                              </div>


                          </div>
                      </div>
                  </CardContent>
                  <CardActions>
                      {/*<div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>*/}

                      {/*    /!* Cancel Button *!/*/}
                      {/*    <Button variant="outlined"*/}
                      {/*            onClick={onCancel}>*/}
                      {/*        {t('common.cancel')}*/}
                      {/*    </Button>*/}

                      {/*    &nbsp;&nbsp;*/}

                      {/*    /!* Okay Button *!/*/}
                      {/*    <LoadingButton loading={isLoading}*/}
                      {/*                   type="submit"*/}
                      {/*                   variant="contained"*/}
                      {/*                   className="bg-custom-gradient">*/}
                      {/*        {isEditMode ? t('common.edit') : t('common.add')}*/}
                      {/*    </LoadingButton>*/}
                      {/*    &nbsp;*/}
                      {/*</div>*/}
                  </CardActions>
              </form>
          </div>
        //     </Card>
        // </Box>
    );
}

export default BankdetailForm;
