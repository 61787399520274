import React from 'react';
import {useStyles} from './Styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import {RootStateOrAny, useDispatch, useSelector} from "react-redux";


import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";


function Home() {
    const classes = useStyles();

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            <Header />

            <Sider />


            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="h-100 d-flex align-items-center justify-content-center">
                <img className="img-fluid text-center mx-auto my-5" style={{}}
                     src={require('../../../../assets/illustration/Dashboard.png')}/>
                </div>
            </Box>
        </Box>
    );
}

export default Home;
