import React, { useEffect } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import {RootStateOrAny, useSelector} from "react-redux";
import {useTranslation, Trans} from 'react-i18next';
import {AuthGuard} from "./Guards/AuthGuard/AuthGuard";

import RoleMain from "../Components/Screens/Dashboard/Role/RoleMain/RoleMain";
import BankdetailMain from "../Components/Screens/Dashboard/Bankdetail/BankdetailMain/BankdetailMain";
import EducationMain from "../Components/Screens/Dashboard/Education/EducationMain/EducationMain";
import TitleMain from "../Components/Screens/Dashboard/Title/TitleMain/TitleMain";
import ApplicationMain from "../Components/Screens/Dashboard/Application/ApplicationMain/ApplicationMain";
import OnboardCreate from '../Components/Screens/Onboard/OnboardCreate/OnboardCreate';
import DepartmentMain from '../Components/Screens/Dashboard/Department/DepartmentMain/DepartmentMain';
import ApplicationDocumentsMain from '../Components/Screens/Dashboard/ApplicationDocuments/ApplicationDocumentsMain/ApplicationDocumentsMain';
import OnboardComplete from '../Components/Screens/Onboard/OnboardComplete/OnboardComplete';
import RegisterFace from '../Components/Screens/FacialRecognition/RegisterFace/RegisterFace';
import LoginFace from '../Components/Screens/FacialRecognition/LoginFace/LoginFace';
import Camera from '../Components/Screens/Dashboard/Camera/CameraMain/CameraMain';
import Event from '../Components/Screens/Dashboard/Event/EventMain/EventMain';

// %TEMPLATE_IMPORT_COMPONENT%







function Router() {
    const {t, i18n} = useTranslation();

    // get current language
    // const language = useSelector(
    //     (state: RootStateOrAny) => state.global.global.language,
    // );

    // switch language here
    // i18n.changeLanguage('en')

    // set title (if same globally)
    useEffect(() => {
        document.title = 'iTalk2u';
    });

    return (
        <BrowserRouter>
            {/*<Header/>*/}
            <Routes>
                <Route path="/" element={<OnboardCreate/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/logout" element={<Logout/>}/>

                <Route path="/onboard" element={<OnboardCreate/>}/>
                {/*<Route path="/completeOnboard" element={<OnboardComplete/>}/>*/}

                <Route path="/registerFace" element={<RegisterFace/>}/>
                <Route path="/loginFace" element={<LoginFace/>}/>

                <Route path="/dashboard" element={<AuthGuard/>}>
                    <Route path="/dashboard" element={<Home/>}/>
                </Route>

                <Route path="/role" element={<AuthGuard />} >
                      <Route path="/role" element={<RoleMain />} />
                </Route>

                <Route path="/bankdetail" element={<AuthGuard />} >
                      <Route path="/bankdetail" element={<BankdetailMain />} />
                </Route>

                <Route path="/camera" element={<AuthGuard />} >
                    <Route path="/camera" element={<Camera />} />
                </Route>

                <Route path="/events" element={<AuthGuard />} >
                    <Route path="/events" element={<Event />} />
                </Route>

                <Route path="/education" element={<AuthGuard />} >
                      <Route path="/education" element={<EducationMain />} />
                </Route>

                <Route path="/title" element={<AuthGuard />} >
                      <Route path="/title" element={<TitleMain />} />
                </Route>

                <Route path="/application" element={<AuthGuard />} >
                      <Route path="/application" element={<ApplicationMain />} />
                </Route>

                <Route path="/documents" element={<AuthGuard />} >
                    <Route path="/documents" element={<ApplicationDocumentsMain />} />
                </Route>

                <Route path="/department" element={<AuthGuard />} >
                    <Route path="/department" element={<DepartmentMain />} />
                </Route>

                {/* %TEMPLATE_INSERT_ROUTE%  */}





            </Routes>
        </BrowserRouter>
    );
}

export default Router;
