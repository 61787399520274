// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    left: {
        height: '80vh',
    }
});

export { useStyles }
